import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { useController } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { TabOptions, TernaryTabList } from "../TernaryTabList";
import { Button } from '../../../ui/atoms/Button';
import { Spinner } from "../../atoms/Spinner";
import { AiAPI } from '../../../features/utils/api/requests/ai-requests';
interface LanguageChangerProps {
    name: string;
    fields?: string[];
    onLoadingChange?: (isLoading: boolean) => void;
    onDuplicateBlocks?: () => void;
}

export const langTabs: TabOptions[] = [
    {
        label: "English",
        Icon: "🇬🇧",
        value: "en"
    },
    {
        label: "Russian",
        Icon: "🇷🇺",
        value: "ru"
    },
    {
        label: "Italian",
        Icon: "🇮🇹",
        value: "it"
    },
    {
        label: "French",
        Icon: "🇫🇷",
        value: "fr"
    },
    {
        label: "German",
        Icon: "🇩🇪",
        value: "de"
    },
    {
        label: "Spanish",
        Icon: "🇪🇸",
        value: "es"
    }
];

export const LanguageTabChanger: FC<LanguageChangerProps> = observer(
    ({ name, fields, onLoadingChange, onDuplicateBlocks }) => {
        const { control, getValues, setValue } = useFormContext();
        const [isLoading, setIsLoading] = useState(false);
        const {
            field: { value, onChange }
        } = useController({ name, control });

        const handleAITranslation = async () => {
            setIsLoading(true);
            if (onLoadingChange) onLoadingChange(true);

            if (onDuplicateBlocks) {
                onDuplicateBlocks();
            }
            if (fields && fields.length > 0) {
                const translationPromises = fields.map(async (field) => {
                    const { maxIndexCount, hasIndexes, hasBody } = createMissingIndexesForLanguages(field, langTabs, getValues, setValue);
                    const fieldKey = hasBody ? "body" : "text";
                    const getFieldValues = (index?: number) => {
                        return langTabs.reduce((acc, lang) => {
                            const baseFieldPath = hasIndexes ? `${field}.${lang.value}.${index}.${fieldKey}` : `${field}.${lang.value}`;
                            const value = getValues(`${baseFieldPath}`);

                            if (value !== undefined && value !== null) {
                                acc[lang.value] = value;
                            }

                            return acc;
                        }, {} as Record<string, string>);
                    };

                    try {
                        if (hasIndexes) {
                            const indexedTranslationPromises = Array.from({ length: maxIndexCount }, (_, i) => (async () => {
                                const values = getFieldValues(i);
                                if (Object.keys(values).length > 0) {
                                    const translations = await AiAPI.translateFields(langTabs.map(lang => lang.value), values);

                                    Object.entries(translations).forEach(([lang, text]) => {
                                        setValue(`${field}.${lang}.${i}.${fieldKey}`, text);
                                    });
                                }
                            })());

                            await Promise.all(indexedTranslationPromises);
                        } else {
                            const values = getFieldValues();
                            const translations = await AiAPI.translateFields(langTabs.map(lang => lang.value), values);

                            Object.entries(translations).forEach(([lang, text]) => {
                                setValue(`${field}.${lang}`, text);
                            });
                        }
                    } catch (error) {
                        console.error(`Error translating ${field}:`, error);
                    }
                });

                await Promise.all(translationPromises);
            }

            setIsLoading(false);
            if (onLoadingChange) onLoadingChange(false);
        };

        const createMissingIndexesForLanguages = (
            field: string,
            langTabs: TabOptions[],
            getValues: any,
            setValue: any
        ) => {


            const { langCount, hasIndexes, hasBody } = countFieldIndexes(field, langTabs, getValues);

            if (!hasIndexes) return { maxIndexCount: 0, hasIndexes };

            const maxIndexCount = Math.max(...Object.values(langCount));

            if (hasBody) return { maxIndexCount, hasIndexes, hasBody };

            langTabs.forEach(lang => {
                const currentCount = langCount[lang.value];

                if (currentCount < maxIndexCount) {
                    for (let i = currentCount; i < maxIndexCount; i++) {
                        const newField = `${field}.${lang.value}.${i}.text`;
                        setValue(newField, "");
                    }
                }
            });

            return { maxIndexCount, hasIndexes, hasBody };
        };

        const countFieldIndexes = (field: string, langTabs: TabOptions[], getValues: any) => {
            const langCount = langTabs.reduce((acc, lang) => ({ ...acc, [lang.value]: 0 }), {} as Record<string, number>);

            const hasBody = langTabs.some(lang => {
                for (let i = 0; ; i++) {
                    const fieldPath = `${field}.${lang.value}.${i}.body`;
                    if (getValues(fieldPath) !== undefined) {
                        return true;
                    }
                    const imgFieldPath = `${field}.${lang.value}.${i}.imageId`;
                    if (getValues(imgFieldPath) === undefined) {
                        break;
                    }
                }
                return false;
            });
            const fieldKey = hasBody ? "body" : "text";

            langTabs.forEach(lang => {
                let index = 0;
                while (true) {
                    const fieldPath = `${field}.${lang.value}.${index}.${fieldKey}`;
                    const fieldValue = getValues(fieldPath);

                    const imgFieldPath = `${field}.${lang.value}.${index}.imageId`;
                    const imgValue = getValues(imgFieldPath);

                    if (fieldValue !== undefined || imgValue !== undefined) {
                        langCount[lang.value]++;
                        index++;
                    } else {
                        break;
                    }
                }
            });

            const hasIndexes = Object.values(langCount).some(count => count > 0);
            return { langCount, hasIndexes, hasBody };
        };

        return (
            <div>
                <TernaryTabList
                    value={value}
                    onChange={onChange}
                    options={langTabs}
                />
                {fields && fields.length > 0 && (
                    <Button
                        htmlType="button"
                        text={isLoading ? "Translating..." : "AI Translate"}
                        onClick={handleAITranslation}
                        className="mt-5"
                        isDisabled={isLoading}
                    >
                        {isLoading && <Spinner />}
                    </Button>
                )}
            </div>
        );
    }
);
